//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { GatsbyImage } from 'gatsby-plugin-image'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const Snippet = ({ home, post }) => (
  <div
    class={`${
      home ? 'post-home' : ''
    } post-item round-corners-top round-corners-bottom m-2 d-flex flex-column`}
  >
    <Link to={post.frontmatter.path}>
      <GatsbyImage
        className="rounded-corners"
        imgClassName="rounded-corners"
        image={post.frontmatter.featuredImage.childImageSharp.gatsbyImageData}
        placeholder="blurred"
      />
    </Link>

    <Link
      className={`post-text-link d-flex flex-grow-1 flex-column ${
        home
          ? 'align-items-center justify-content-center'
          : 'align-items-start justify-content-start'
      }
      `}
      to={post.frontmatter.path}
    >
      <span class="post-text-date small">
        {!home ? post.frontmatter.date : null}{' '}
        {/* {post.frontmatter.date &&
        post.frontmatter.updated &&
        post.frontmatter.date !== post.frontmatter.updated &&
        !home &&
        !post.frontmatter.updated.includes('Invalid') ? (
          <span class="post-text-date pb-2 dkblue-1 small ml-1">
            Updated {post.frontmatter.updated}
          </span>
        ) : null} */}
      </span>
      {post.frontmatter.path.includes('customers') ? (
        <span class="small font-weight-bold sky-1 text-uppercase resources-tile-label">
          Customer success
        </span>
      ) : null}
      <div>
        <span class="post-text">{post.frontmatter.title}</span>
        {/* <FaArrowRight size={18} class="black-4 ml-2" /> */}
      </div>
    </Link>
  </div>
)
export default Snippet
