//React
import React, { useState } from 'react'
import { connect } from 'react-redux'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Actions
import { updateBlogFilterTag } from '../actions/updateCampaign'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../components/layout'
import Metatags from '../components/marketing/metatags'
import PostLink from '../components/marketing/postLink'

//Icons
import { FaArrowRight } from 'react-icons/fa'
import { filter } from 'async'

//Redux
const mapStateToProps = ({ blogFilterTag }) => {
  return { blogFilterTag }
}
const mapDispatchToProps = (dispatch) => ({
  updateBlogFilterTag: (tag) => {
    dispatch(updateBlogFilterTag(tag))
  },
})

const IndexPage = ({
  data: {
    allMdx: { edges },
  },
  pageContext,
  updateBlogFilterTag,
  blogFilterTag,
}) => {
  console.log('**edges')
  console.log(edges)
  console.log('**pageContext')
  console.log(pageContext)

  const { limit, skip, currentPage, numPages, publishedPosts } = pageContext
  let pageCount = numPages

  let Posts = edges
    .filter(
      (edge) =>
        edge.node.frontmatter.date && edge.node.frontmatter.publish === 1
    ) // You can filter your posts based on some criteria
    .map((edge) => (
      <PostLink home={false} key={edge.node.id} post={edge.node} />
    ))

  if (blogFilterTag !== 'all') {
    console.log('****publishedPosts')
    console.log(publishedPosts)

    const filteredPosts = publishedPosts.filter((edge) => {
      let tagsArr = []
      if (
        edge.node.frontmatter.articleTags &&
        edge.node.frontmatter.articleTags !== ''
      ) {
        tagsArr = edge.node.frontmatter.articleTags.split(',')
      }
      console.log('**edge.node.frontmatter.title')
      console.log(edge.node.frontmatter.title)
      console.log('**tagsArr')
      console.log(tagsArr)
      return (
        edge.node.frontmatter.date &&
        edge.node.frontmatter.publish === 1 &&
        tagsArr.includes(blogFilterTag)
      )
    }) // You can filter your posts based on some criteria

    console.log('****filteredPosts')
    console.log(filteredPosts)
    Posts = publishedPosts
      .filter((edge) => {
        let tagsArr = []
        if (
          edge.node.frontmatter.articleTags &&
          edge.node.frontmatter.articleTags !== ''
        ) {
          tagsArr = edge.node.frontmatter.articleTags.split(',')
        }
        console.log('**edge.node.frontmatter.title')
        console.log(edge.node.frontmatter.title)
        console.log('**tagsArr')
        console.log(tagsArr)
        return (
          edge.node.frontmatter.date &&
          edge.node.frontmatter.publish === 1 &&
          tagsArr.includes(blogFilterTag)
        )
      }) // You can filter your posts based on some criteria
      .map((edge) => (
        <PostLink home={false} key={edge.node.id} post={edge.node} />
      ))

    // pageCount = Math.ceil(Posts.length / limit)
  }

  console.log('Posts')
  console.log(Posts)

  let currentPageNum = currentPage

  //these are recopied below
  let isFirst = currentPageNum === 1
  let isLast = currentPageNum === pageCount
  let prevPage = currentPageNum - 1 === 1 ? '' : (currentPageNum - 1).toString()
  let nextPage = (currentPageNum + 1).toString()

  const tagFilterArr = [
    { title: 'All', value: 'all' },
    { title: 'Strategy guides', value: 'guide' },
    { title: 'New features', value: 'feature' },
    { title: 'Loyalty', value: 'loyalty' },
    { title: 'Social impact', value: 'socialimpact' },
  ]

  return (
    <Layout>
      <Metatags
        title="Sidepath - Web3 Resources for Brands"
        description="See articles and info about NFTs for brands and marketing"
        image={`https://${process.env.HOSTNAME}/static/87ea05efda8ffb43f3bb330561ae72bd/aa36a/feature-nft-3-mint-loyalty-short.png`}
      />
      <div class="gradient-callout-overcast">
        <div class="container container-page container-about-sub">
          <div class="row">
            <div class="col-lg-8 d-flex flex-column justify-content-center text-center text-lg-left">
              <h1 class="display-2 font-weight-medium white-1">
                Web3 Resources &amp; Articles
              </h1>
              {currentPageNum > 1 ? (
                <p class="display-4 white-4">Page {currentPageNum}</p>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div class="container container-page container-features">
        <div class="row d-flex">
          <div class="col-lg-12 d-flex flex-row flex-wrap align-items-stretch justify-content-center mb-3">
            {tagFilterArr.map((item) => (
              <Link
                to="/resources/"
                className={`btn btn-sm btn-${
                  blogFilterTag === item.value ? '' : 'outline-'
                }sky mx-2`}
                onClick={() => {
                  updateBlogFilterTag(item.value)
                }}
              >
                {item.title}
              </Link>
            ))}
          </div>
        </div>
        <div class="row d-flex">
          <div class="col-lg-12 d-flex flex-row flex-wrap align-items-stretch  justify-content-center">
            {Posts}
          </div>
        </div>
        {blogFilterTag === 'all' ? (
          <div class="row d-flex mt-4 pt-4">
            <div class="col-lg-12 d-flex flex-row flex-wrap align-items-stretch  justify-content-center">
              <nav>
                <ul class="pagination">
                  {!isFirst && (
                    <li className="page-item">
                      <Link
                        to={`/resources/${prevPage}`}
                        className="page-link"
                        rel="prev"
                      >
                        ← Previous
                      </Link>
                    </li>
                  )}
                  {Array.from({ length: pageCount }, (_, i) => (
                    <li
                      className={`page-item ${
                        currentPageNum === i + 1 ? 'active' : ''
                      }`}
                    >
                      <Link
                        key={`pagination-number${i + 1}`}
                        to={`/resources/${i === 0 ? '' : i + 1}`}
                        className="page-link"
                      >
                        {i + 1}
                      </Link>
                    </li>
                  ))}

                  {!isLast && (
                    <li className="page-item">
                      <Link
                        to={`/resources/${nextPage}`}
                        className="page-link"
                        rel="next"
                      >
                        Next →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
          </div>
        ) : null}
      </div>
    </Layout>
  )
}

// export default IndexPage
export default connect(mapStateToProps, mapDispatchToProps)(IndexPage)

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: {
          path: { glob: "/(article|customers|landing)/*" }
          publish: { eq: 1 }
        }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            updated(formatString: "MMMM DD, YYYY")
            path
            title
            publish
            articleTags
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 300)
              }
            }
          }
        }
      }
    }
  }
`
